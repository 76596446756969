<template>
  <div class="checkBtn">
    <ColorTextBtn @click="showDialog">查看</ColorTextBtn>
    <el-dialog title="分销商业绩统计" :visible.sync="dialogVisible" width="50%">
      <div class="userInfo" v-loading="loading">
        <p class="infoTitle">分销商: {{ rowData.mainUser }}<span v-if="rowData.userStatus === 2" style="color:red">(已注销)</span></p>
        <div class="infoContent">
          <div class="infoContent-left">
            <p>销售总额: ￥{{ numData.productAmount || '0' }}</p>
            <p>订单总量: {{ numData.orderCount || '0' }}</p>
            <p>销售产品: {{ numData.productCount || '0' }}</p>
          </div>
          <div class="infoContent-right"></div>
        </div>
      </div>
      <p class="title-bar">
        <span>订单记录</span>
      </p>
      <CommonTable
        height="300px"
        ref="commonTableRef"
        :tableLoading="loading"
        :selection="false"
        :cols="userTableDetailCols"
        :infoData="detailTableData"
        :span-method="tableSpanMethod"
      >
        <template #styleNameSlot="{ scoped: { row } }">
          {{ row.styleName }}
        </template>
      </CommonTable>
      <PaginationBar
        @refreshTableEventFun="refreshTableEventFun"
        :total="total"
        :size="size"
        :page="page"
      ></PaginationBar>
    </el-dialog>
  </div>
</template>

<script>
import { userTableDetailCols } from './userTableDetailCols'
import { getMainUserDetail } from '@/api/statistics.js'
import { handleTableSpan, handleObjectSpanMethod } from '@/utils/mergeTable'
export default {
  components: {},
  props: {
    rowData: {
      type: Object,
      required: true
    },
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      detailTableData: [],
      userTableDetailCols,
      loading: false,
      total: 0,
      size: 10,
      page: 1,
      numData: {},
      notEmpty: false,
      mergeKeys: ['orderDate', 'orderCode'],
      rowSpanObj: {}
    }
  },
  computed: {},
  watch: {
    detailTableData(newVal) {
      if (this.mergeKeys.length) {
        this.rowSpanObj = handleTableSpan(this.mergeKeys, newVal)
      }
    },

    dialogVisible(newVal) {
      if (!newVal) {
        this.detailTableData = []
      }
    }
  },
  methods: {
    showDialog() {
      this.page = 1
      this.getDetail()
      this.dialogVisible = true
    },

    refreshTableEventFun({ page, size }) {
      this.page = page
      this.size = size
      this.getDetail()
      this.$nextTick(function () {
        this.$refs.commonTableRef.table.bodyWrapper.scrollTop = 0
      })
    },
    // 获取用户业绩详情
    async getDetail() {
      const { startCreateTime, endCreateTime, orderType } = this.query
      const page = {
        pageIndex: this.page,
        pageSize: this.size
      }
      const newQuery = { mainAccountUid: this.rowData.mainAccountUid, page, orderType }
      if (startCreateTime && endCreateTime) {
        newQuery.startCreateTime = startCreateTime
        newQuery.endCreateTime = endCreateTime
      }
      try {
        this.loading = true
        const res = await getMainUserDetail(newQuery)
        const {
          detail: { list, orderCount, productAmount, productCount }
        } = res
        this.detailTableData = list || []
        this.numData = { orderCount, productAmount, productCount }
        const {
          page: { total }
        } = res
        this.total = total
      } finally {
        this.loading = false
      }

      // console.log('res', res)
    },
    tableSpanMethod(tableObj) {
      return handleObjectSpanMethod(tableObj, this.mergeKeys, this.rowSpanObj, this.notEmpty)
    }
  }
}
</script>

<style scoped lang="scss">
.checkBtn {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #ccc;
    .el-dialog__title {
      font-size: 14px;
    }
  }
  .userInfo {
    border: 1px solid #ccc;
    .infoTitle {
      border-bottom: 1px solid #ccc;
      padding: 10px 10px;
      margin: 0;
    }
    .infoContent {
      padding: 0 10px;
      display: flex;
      .infoContent-left,
      .infoContent-right {
        width: 50%;
      }
    }
  }
  .title-bar {
    border-left: 2px solid $color-primary;
    span {
      padding-left: 10px;
    }
  }
}
</style>
